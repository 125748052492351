$primary: #28A8E0 !default;
$secondary: #8f5325 !default;
$success: #3e8d63 !default;
$info: #7854e4 !default;
$warning: #b8c924 !default;
$danger: #d62518 !default;
$light: #f8f9fa !default;
$dark: #343a40 !default;
$accent: #da6d25 !default;
$gray: #B5B4B8 !default;
$backgroundLogin: #fff !default;
