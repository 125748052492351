* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'FSPFBeauSansPro' !important;
  font-display: auto;
  src: local('FSPFBeauSansPro'), url('./assets/fonts/FSPFBeauSansPro/FSPFBeauSansPro-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: 'FSPFBeauSansPro', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
}
