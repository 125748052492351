body {
    color: #4a4a4a;
    font-size: 14px !important;
    word-break: break-word;
    word-wrap: break-word;
    background: #F3ECEC;
    // font-family: 'Sarabun', Helvetica, Arial, sans-serif;
}

body a {
    color: #007ad9;
    text-decoration: none;
}

a,
button {
    transition: all 0.1s linear 0s;
}

.borderBlockLogin {
    border: 1px solid $primary;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    min-height: 70% !important;
    box-sizing: border-box;
    align-self: center;
    background-color: $backgroundLogin !important;
    width: 500px;
    max-width: 500px !important;
    div {
        text-align: left;
    }
}

.backgroundScreenLogin {
   text-align: -webkit-center;
}

.backgroundLoginBase {
    background-color: $backgroundLogin !important;
}

.sipGenDashBoard {
    border-radius: 5px;
    overflow: hidden;
    padding: 15px;
    background: #ffffff;
    display: block;
    box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053);
    position: relative;

    .highcharts-title {
        font-family: Sarabun;
    }

    .highcharts-legend {

        span,
        text {
            font-weight: normal !important;
            font-family: Sarabun;
        }
    }
}

.btn {
    padding: 0 12px;
    height: 38px;
    line-height: 32px;
    box-sizing: border-box;
    min-width: 150px;
}

.btn.disabled,
.btn:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.sipTitleContainer {
    margin: 0;
}

.sipTitle {
    color: $primary;
    font-size: 22px;
    display: inline-block;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    font-weight: '800';

    .btn {
        box-shadow: none;
        padding: 0 5px;
        background: none !important;
        border: none;
        margin-right: 8px;
        position: relative;
        top: -3px;

        &.sipTitleBtnBack {
            color: #000 !important;
            padding: 0;
        }
    }
}

.sipBgWhiteContainer {
    background: #fff;
    padding: 15px 0;
    margin: 0 0 15px;
    display: block;
    box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053);
}

.sipInputBlock {
    margin-bottom: 15px;

    h3 {
        color: $primary;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;

        .addNewPackageTabItemBtn {
            background: $primary  !important;
            // border: none;
            // font-size: 12px;
            float: right;
            // padding: 0 12px;
            color: #fff !important;

            // border-radius: 13px;
            // min-height: 0;
            // height: 25px;
            // line-height: 22px;
            img {
                position: relative;
                top: -1px;
                margin-right: 6px !important;
            }
        }
    }
}

.sipInputItem {
    margin-bottom: 10px;

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        width: 100%;
    }

    &>label {
        padding-right: 0;
    }

    &>div {
        box-sizing: border-box;

        input.form-control {
            box-sizing: border-box;
        }

        select.form-control {
            padding: 0 0 0 7px;
        }
    }
}

.sipContentContainer {
    position: relative;
    background: #fff;
    padding: 15px;
    margin: 0 0 5px;
    box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053);
    height: 100%;
}

.sipContentContainer2 {
    position: relative;
    background: #fff;
    padding: 15px;
    margin: 0 0 15px;
    box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053);
    border-radius: 3px;
}


.sipContentContainer3 {
    position: relative;
    background: #fff;
    // padding: 15px;
    margin: 0 0 15px;
    box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053);
    border-radius: 3px;
}

table {
    padding-bottom: 20px;

    thead {
        th {
            color: $primary;
            padding: 10px;
        }
    }

    tbody {
        td {
            padding: 5px;
        }
    }
}

.sipTitleModalCauHinhDiaChi {
    // width: '80%';
    // margin: 100px auto;
    max-width: 80%;

    .modal-content {
        border: none;

        .modal-header {
            background-color: $primary;
            padding: 10px 15px;

            .modal-title {
                font-size: 18px;
                color: #fff;
                font-weight: 600;
            }

            .close {
                color: #fff;
                opacity: 0.8;
                font-size: 24px;
                padding: 0;
                margin: 0;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .modal-body {
            padding: 20px;

            .form-group {
                label {
                    color: $primary;
                    font-weight: 600;
                }
            }
        }

        .modal-footer {
            border-top: none;
            padding: 0 20px 20px;
            justify-content: center;
            //.btn {
            //  text-transform: uppercase;
            //  padding: 8px 20px;
            //  font-weight: 600;
            //  color: #fff !important;
            //  &.btn-primary {
            //    background: linear-gradient(180deg, #18a796 0%, #47c4ae 100%);
            //  }
            //}
        }
    }
}

.sipTitleModalCreateNew {

    // width: 568px;
    // margin: 100px auto;
    // max-width: calc(100% - 20px);
    .modal-content {
        border: none;

        .modal-header {
            background-color: $primary;
            padding: 10px 15px;

            .modal-title {
                font-size: 18px;
                color: #fff;
                font-weight: 600;
            }

            .close {
                color: #fff;
                opacity: 0.8;
                font-size: 24px;
                padding: 0;
                margin: 0;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .modal-body {
            padding: 20px;

            .form-group {
                label {
                    color: $primary;
                    font-weight: 600;
                }
            }
        }

        .modal-footer {
            border-top: none;
            padding: 0 20px 20px;
            justify-content: center;
            //.btn {
            //  text-transform: uppercase;
            //  padding: 8px 20px;
            //  font-weight: 600;
            //  color: #fff !important;
            //  &.btn-primary {
            //    background: linear-gradient(180deg, #18a796 0%, #47c4ae 100%);
            //  }
            //}
        }
    }
}

.nav-group-items .nav-item a {
    font-size: 13px;
}

.sipOrderInputCol {
    padding: 0 10px;
}

.sipOrderBtnSave {
    img {
        position: relative;
        top: -2px;
    }
}

.sipTableContainer {
    display: block;
    background: #fff;
    box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053);
    border-radius: 3px;
    width: 100%;
    overflow: auto;
    margin: 0;

    .table {
        color: #191919;
        margin: 0;
        min-width: 800px;

        .row {
            margin-left: 10px;
            margin-right: 10px;
            display: flex;
            flex-wrap: inherit;
        }

        th {
            border-top: none;
            color: $primary;
            font-weight: 600;
        }

        th,
        td {
            padding: 8px;
            vertical-align: middle;

            &:first-child {
                padding: 8px 10px 8px 20px;
            }
        }

        .SipTableFunctionIcon {
            white-space: nowrap;

            .btn {
                padding: 5px;
                background: none !important;
                border: none;
                box-shadow: none;
                margin: 0 5px;
                font-size: 12px;
                height: 28px;
                min-height: 0;
            }
        }

        .SipTableFunctionIcon1 {
            padding: 0;
            background: none !important;
            border-radius: 6px;
            border-color: $primary;
            box-shadow: none;
            margin: 0 5px;
            height: 32px;
            width: 102px;
            color: $primary;
            min-height: 0;
        }

        .SipTableFunctionIcon2 {
            padding: 0;
            background: none !important;
            border-radius: 6px;
            border-color: #ed436c;
            box-shadow: none;
            margin: 0 5px;
            height: 32px;
            width: 92px;
            color: #ed436c;
            min-height: 0;
        }

        .SipTableFunctionIcon {
            padding: 0;
            background: none !important;
            border: none;
            box-shadow: none;
            margin: 0 5px;
            font-size: 12px;
            height: 28px;
            min-height: 0;
        }
    }
}

.sipTabContainer {
    position: relative;
    background: #fff;
    margin: 0 0 15px;
    box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053);

    .nav-tabs {
        border-bottom: 3px solid #d3d3d3;
        background: #fff;

        .nav-item {
            margin-bottom: -3px;

            .nav-link {
                border: none;
                border-bottom: 3px solid #d3d3d3;
                color: #6b6f83;
                padding: 10px 20px;

                .badge {
                    background-color: #999;
                    padding: 5px 5px 3px;
                    margin-left: 8px;
                    font-size: 13px;
                    border-radius: 8px;
                }

                &.active {
                    border-bottom-color: $primary;
                    color: $primary;
                    font-weight: bold;

                    .badge {
                        background-color: $primary;
                    }
                }
            }
        }
    }

    .tab-content {
        .tab-pane {
            padding: 0;

            &.sipTabContent {
                padding: 15px;
            }

            .sipTableContainer {
                margin: 0;
                box-shadow: none;
            }
        }
    }
}


.sipTabContainer {
    position: relative;
    background: #fff;
    margin: 0 0 15px;
    box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053);

    .nav-tabs {
        border-bottom: 3px solid #d3d3d3;
        background: #fff;

        .nav-item {
            margin-bottom: -3px;

            .nav-link {
                border: none;
                border-bottom: 3px solid #d3d3d3;
                color: #6b6f83;
                padding: 10px 20px;

                .badge {
                    background-color: #999;
                    padding: 5px 5px 3px;
                    margin-left: 8px;
                    font-size: 13px;
                    border-radius: 8px;
                }

                &.active {
                    border-bottom-color: $primary;
                    color: $primary;
                    font-weight: bold;

                    .badge {
                        background-color: $primary;
                    }
                }
            }
        }
    }

    .tab-content {
        .tab-pane {
            padding: 0;

            &.sipTabContent {
                padding: 15px;
            }

            .sipTableContainer {
                margin: 0;
                box-shadow: none;
            }
        }
    }
}

.sipTabContainer .nav-tabs {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: block;
    padding-bottom: 3px;
    border: none;

    .nav-item {
        display: inline-block;
    }
}

.sipFlatContainer {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
}

.hideInput {
    display: none !important;
}

.sipTitleRightBlock {
    display: flex;
    white-space: nowrap;

    &>.btn {
        img {
            margin-right: 5px;
            position: relative;
            top: -1px;
        }

        &.sipTitleRightBlockBtnIcon {
            background: #ffffff !important;
            border: 1px solid #e4e8eb;
            box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053);
            padding: 0 10px;
            height: 32px;

            img,
            i {
                color: #a1a6ab;
                margin: 0;
                font-size: 16px;
            }
        }
    }
}

.sipOrderInputRow {
    margin: 0 -10px;
}

.sipFlatBtn {
    padding: 0 !important;
    font-size: inherit !important;
    background: none !important;
    border: none !important;
    line-height: inherit;
    font-weight: inherit;
    color: $primary  !important;
    box-shadow: none !important;
    position: relative !important;
    top: -1px !important;
    min-height: 0 !important;
    height: auto !important;
}

.form-check-input:checked {
    background-color: $primary  !important;
    border-color: $primary  !important;
}

.text-normal {
    font-weight: normal !important;
}

.text-style-normal {
    font-style: normal !important;
}

.text-semibold {
    font-weight: 600 !important;
}

.text-bold {
    font-weight: 700 !important;
}

.sipBoxShadow {
    box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053) !important;
}

.rotate-90 {
    transform: rotate(-90deg);
}

/*_________________________color for placeholder___________________________*/

input.form-control::placeholder {
    // font-style: italic;
    opacity: 1;
    color: #888282 !important;
    border-color: #D1D1D1 !important;
    /* Firefox */
}

input.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    // font-style: italic;
    color: #888282 !important;
    border-color: #D1D1D1 !important;
}

input.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    // font-style: italic;
    color: #888282 !important;
    border-color: #D1D1D1 !important;
}

input.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    // font-style: italic;
    color: #888282 !important;
    border-color: #D1D1D1 !important;
}

input.form-control::-moz-placeholder {
    /* Firefox 19+ */
    // font-style: italic;
    color: #888282 !important;
    border-color: #D1D1D1 !important;
}

input.form-control:-moz-placeholder {
    /* Firefox 18- */
    // font-style: italic;
    color: #888282 !important;
    border-color: #D1D1D1 !important;
}

/*____________Frames upload file______________*/
.sipBgWhiteUploadFile {
    background: #fff;
    padding: 16px 0 27px 16px;
    margin: 0 0 15px 0;
    display: flex;
    box-shadow: 0 2px 5px rgba(159, 163, 183, 0.51053);
}

.sipFramesUploadFile {
    border: 1px dashed #d5d5d5;
    border-radius: 3px;
    height: 78px;
    width: 450px;
    padding: 12px;
}

.sipColModal {
    padding: 0 15px 0 0;
}

.sipFilterContainer {
    display: flex;
    padding: 15px 8px;

    &.childButton {
        &.row {
            justify-content: flex-end;
            margin-right: 0;
        }
    }

    .sipFilterCol {
        flex: 0 0 26%;
        padding: 0 7px;

        &.col {
            max-width: fit-content;
        }

        .react-bootstrap-daterangepicker-container {
            width: 100%;

            input.form-control {
                padding: 0 12px 0 32px;
            }
        }

        input.form-control,
        select.form-control {
            box-sizing: border-box;
        }

        &.sipFilterColBtn {
            flex: 0 0 22%;
            padding: 0 7px;

            .btn {
                margin-right: 15px;
            }
        }
    }
}

.form-control {
    height: calc(1.5em + 0.75rem + 2px);
    // height: 58px;
    font-size: .875rem;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    border-radius: 10px;
}

.input-group-text {
    .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .MuiButtonBase-root  .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
        padding: 0 6px 0 6px !important;
    }
}

.input-group-text {
    padding: 0 0.375rem 0 0.375rem !important;
    background: #F2F2F2;
}

.sipInputItemError {
    color: #eb3c66;
    margin-top: 3px;
}

.react-bootstrap-daterangepicker-container {
    width: 100%;

    input.form-control {
        padding: 0 12px 0 32px;
    }
}

.sipTitle {
    color: $primary;
    font-size: 22px;
    display: inline-block;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    font-weight: '800';

    .btn {
        box-shadow: none;
        padding: 0 5px;
        background: none !important;
        border: none;
        margin-right: 8px;
        position: relative;
        top: -3px;

        &.sipTitleBtnBack {
            color: #000 !important;
            padding: 0;
        }
    }
}

.padding-10 {
    padding: 10px;
}

/*______________________________________________RESPONSIVE__________________________________________________________*/

@media screen and (min-width: 992px) {

    html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .main,
    html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .app-footer,
    html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main,
    html:not([dir='rtl']) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 240px;
    }

    .sidebar-fixed .sidebar {
        width: 240px;
    }

    .sidebar-minimized .sidebar .nav-item:hover {
        width: 250px;
        overflow: visible;

        .nav-link {
            background: $primary;
            color: #fff;
        }
    }

    .sidebar-minimized .sidebar .nav-dropdown-items {
        .nav-item {
            width: 250px;

            .nav-link {
                width: 250px;
            }
        }
    }

    .sidebar-minimized .sidebar .nav-link .nav-icon {
        width: 30px;
    }
}

.baseColor {
    color: $primary !important;
}

.baseBackgroundColor {
    background-color: $primary !important;
}

.sipPagination {
    background: #fff;
    border-top: 2px solid #c8ced3;
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pagination {
        margin: 0;
        text-align: center;
        display: block;

        .page-item {
            display: inline-block;
            margin: 0 1px;

            .page-link {
                border: none;
                font-size: 18px;
                color: #52575a;
                padding: 0 5px;
                height: 36px;
                min-width: 36px;
                line-height: 34px;
                cursor: pointer;
            }

            &.selected .page-link {
                background: none;
                border: 1px solid #94979b;
                cursor: auto;
                color: $primary;
            }
        }

        .sipPaginationNext,
        .sipPaginationPrev {
            position: relative;
            top: -3px;

            .page-link {
                color: #fff;
                border-radius: 4px;
                min-width: 32px;
                height: 32px;
                line-height: 30px;
                background: none !important;
                border: none !important;
                box-shadow: none !important;
            }

            &.disabled .page-link {
                opacity: 0.4;
            }
        }

        .sipPaginationNext {
            margin-left: 5px;
        }

        .sipPaginationPrev {
            margin-right: 5px;
        }
    }
}

.auth-wrapper {
    // background-image: url('https://images.unsplash.com/photo-1658572352229-14bbe60b3c5b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    margin: 0px;
}

.sipPaginationTotal {
    &>span {
        font-size: 12px;
        margin-left: 8px;
    }
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    vertical-align: middle;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%232f353a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.list-group-item {
    cursor: pointer
}

.list-group-item :hover {
   background-color: #f6f8fa;
}

//------------------

//---------------------------------------------

@media screen and (min-width: 576px) {

    html:not([dir='rtl']) .sidebar-sm-show.sidebar-fixed .main,
    html:not([dir='rtl']) .sidebar-sm-show.sidebar-fixed .app-footer,
    html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main,
    html:not([dir='rtl']) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 240px;
    }
}

/*____________________________________ULTRA LARGE DESKTOP_______________________________________________________________________*/

@media screen and (min-width: 1500px) {}

/*_____________________________________PC____________________________________________________________________*/
/*___________________________________________________________________________________________________________*/

@media screen and (min-width: 1200px) {
    .hide-pc {
        display: none !important;
    }

    .show-xs {
        display: none !important;
    }
}

/*_____________________________________TABLET & MOBILE_______________________________________________________*/
/*___________________________________________________________________________________________________________*/
/*___________________________________________________________________________________________________________*/

@media screen and (max-width: 1200px) {
    .no-margin-mobile {
        margin: 0 !important;
    }

    .hide-mobile {
        display: none !important;
    }

    /*____________________________________________________________________________________________________*/

    .sidebar {
        width: 240px;
    }

    .app-header .navbar-brand {
        display: none;
    }

    .sipTabContainer .nav-tabs {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        display: block;
        padding-bottom: 3px;
        border: none;

        .nav-item {
            display: inline-block;
        }
    }

    .sipTableContainer .table .SipTableFunctionIcon .btn {
        margin: 0 5px;
    }

    .sipFilterContainer .sipFilterCol {
        flex: 0 0 100%;
        // margin-bottom: 10px;
    }

    .sipFilterContainer .sipFilterCol.sipFilterColBtn {
        margin-bottom: 0;
        flex: 0 0 100%;

        .btn {
            width: 120px;
        }
    }
}


/*____________________________________________________*/
/*_____________________________________TABLET________________________________________________________________*/
/*___________________________________________________________________________________________________________*/
/*___________________________________________________________________________________________________________*/

@media screen and (max-width: 992px) and (min-width: 768px) {
    .show-xs {
        display: none !important;
    }

    /*_____________________________________________________________________________________*/
}

/*_____________________________________MOBILE________________________________________________________________*/
/*___________________________________________________________________________________________________________*/
/*___________________________________________________________________________________________________________*/
@media screen and (max-width: 768px) {
    .no-padding-xs {
        padding: 0 !important;
    }

    .no-margin-xs {
        margin: 0 !important;
    }

    .hide-xs {
        font-weight: bold;
        // display: none !important;
    }

    /*_____________________________________________________________________________________*/

    .app-body .main .container-fluid {
        padding: 20px 15px;
    }

    .sipTitle {
        flex: 0 0 100%;
        width: 100%;
        color: $primary;
    }

    .sipTitleRightBlock {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: right;
        flex-wrap: wrap;

        select.form-control {
            width: 150px;
            display: inline-block;
        }

        &>* {
            margin-bottom: 5px;
        }
    }

    .sipBgWhiteContainer {
        display: flex;
    }

    .borderBlockLogin {
        border: 1px solid $primary;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        min-height: 100%;
        box-sizing: border-box;
        align-self: center;
        div {
            text-align: left;
        }
    }
}

/*_____________________________________SMALL MOBILE__________________________________________________________*/
/*___________________________________________________________________________________________________________*/

@media screen and (max-width: 500px) {
    .sipHeaderSearch {
        display: none !important;
    }
}

div[class*='modal-footer'] {
    button[class*='btn-light'] {
        background-color: #b5b4b8 !important;
        border-color: #b5b4b8 !important;
        color: #ffffff !important;
    }
}

@media (max-width: 1200px) {
    .vt-order-footer {
        position: relative;
    }
}

@media screen and (min-width: 901px) {
    .sipHeaderNoti {
        margin-right: 20px !important;

        .nav-link {
            .badge {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -16px;
                margin-left: 0;
            }
        }

        .dropdown-menu {
            width: 650px;

            .dropdown-item {
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 7px 10px;

                img {
                    width: 30px;
                    margin-right: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .sipHeaderNoti {
        margin-right: 20px !important;

        .nav-link {
            .badge {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -16px;
                margin-left: 0;
            }
        }

        .dropdown-menu {
            width: 350px;

            .dropdown-item {
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 7px 10px;

                img {
                    width: 30px;
                    margin-right: 10px;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .borderBlockLogin {
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
      max-width: 100% !important;
    }
  } 
