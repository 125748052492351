.pointer {
  cursor: pointer;
}

.btn__footer__modal__text {
  margin: 0px;
  font-weight: bold;
  font-size: 13px;
}

.btn__footer__modal__dismiss {
  background-color: $white  !important;
  // border-color: $white  !important;
  color: '#000' !important;
}

.btn-login-with-password {
  background: #FFFFFF;
  border: 1px solid #D1D1D1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

#basic-addon1 .input-group-text {
  background-color: #F2F2F2 !important;
}

.hiden {
  visibility: hidden;
}

.custom-file-upload {
  cursor: pointer;
  color: #c3c3c3;
  background-color: #ffffff;
  width: 250px;
  height: 150px;
  text-align-last: center;
}

.imgUpload {
  width: 230px;
  height: 135px;
}

.custom-file-upload:hover {
  background: #b0b0b0;
  color: #ffffff;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(44, 56, 74, 0.95);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #b1b7c1;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.w-90 {
  width: 90% !important;
}

.phieuGuiTrongNuoc {
  .sipFlatBtn {
    &:hover {
      color: $secondary  !important;
      font-weight: bolder;
    }

    &:focus {
      color: $secondary  !important;
      font-weight: bolder;
    }
  }

  .form-check-label {
    &:hover {
      color: $secondary  !important;
    }

    .font-xs {
      &:hover {
        color: $secondary  !important;
      }

      &:focus {
        color: $secondary  !important;
      }
    }
  }

  .focus-item {
    color: $secondary  !important;
  }
}

.sipInputItemDescription {
  font-size: 11px;
  font-weight: 500;
  margin: 5px 0;
}

.sipLabelFullAddress {
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0;
  font-style: italic;
  color: $dark;
}

.sipInputItem {
  margin-bottom: 10px;

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  &>label {
    padding-right: 0;
  }

  &>div {
    box-sizing: border-box;

    input.form-control {
      box-sizing: border-box;
    }

    select.form-control {
      padding: 0 0 0 7px;
    }
  }
}

.sipInputBlock {
  margin-bottom: 15px;

  h3 {
    color: $primary;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;

    .addNewPackageTabItemBtn {
      background: $primary  !important;
      // border: none;
      // font-size: 12px;
      float: right;
      // padding: 0 12px;
      color: #fff !important;

      // border-radius: 13px;
      // min-height: 0;
      // height: 25px;
      // line-height: 22px;
      img {
        position: relative;
        top: -1px;
        margin-right: 6px !important;
      }
    }
  }
}

.sipSendingCoupon {
  background: #fff;
  margin: 0 0 20px;

  .row {
    margin: 0;
    width: 100%;
  }

  .sipSendingCouponItem {
    width: 28%;
    float: left;
    margin: 10px 0 0;
    min-width: 270px;
    max-width: 350px;

    div[class^='col'],
    div[class*=' col'] {
      padding: 5px 0 0 15px;

      input.form-control {
        text-align: right;
        padding: 0 10px;
        position: relative;
        top: -5px;
        height: 30px;
      }
    }
  }

  .sipLine {
    margin: 15px 0 0;
  }
}

.sipTitleRightBlockInput {
  position: relative;

  i {
    cursor: pointer;
    left: 10px;
    position: absolute;
    top: 10px;
  }

  input.form-control {
    padding: 0 10px 0 30px;
  }
}

.sipTitleBlockInputWithImg {
  position: relative;

  img {
    cursor: pointer;
    left: 15px;
    position: absolute;
    top: 10px;
  }

  input.form-control {
    padding: 0 10px 0 30px;
  }
}

.sipTitleBlockInputWithSpan {
  position: relative;

  span {
    cursor: pointer;
    left: 0px;
    position: absolute;
    top: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  input.form-control {
    padding: 0 10px 0 58px;
  }
}

.sipTitleBlockInputWithSpanRightBtn {
  position: relative;

  span {
    cursor: pointer;
    left: 0px;
    position: absolute;
    top: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  input.form-control {
    padding-right: 10px;
  }

}

.sipTitleBlockInputSpan {
  position: relative;

  span {
    cursor: pointer;
    left: 15px;
    position: absolute;
    top: 10px;
  }

  input.form-control {
    padding: 0 10px 0 30px;
  }

  div {
    cursor: pointer;
    right: 17px;
    position: absolute;
    top: 6px;
  }
}

.sipTitleBlockInput {
  position: relative;

  i {
    cursor: pointer;
    left: 15px;
    position: absolute;
    top: 10px;
  }

  input.form-control {
    padding: 0 10px 0 30px;
  }

  div {
    cursor: pointer;
    right: 17px;
    position: absolute;
    top: 6px;
  }
}

.cardLabelBorder {
  background-color: #0fafa8;
  color: #fff;
  font-weight: bold;
  border-radius: 8px;
  padding: 10px 15px 10px 15px;
}

.sipTitleBlockInput2 {
  position: relative;

  input.form-control {
    padding: 0 65px 0 5px;
  }

  div {
    cursor: pointer;
    right: 17px;
    position: absolute;
    top: 6px;
  }
}

.iconFaThongTinDH {
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.sipFilterColSearch2 {
  width: 172px;
  height: 36px;
  position: relative;

  &>input.form-control,
  &>select.form-control {
    padding: 0 12px 0 32px;
  }

  &>img {
    position: absolute;
    top: 9px;
    // left: 10px;
  }

  &>div {
    display: inline-block;
  }
}

.sipFilterColDateRangeContainer>div {
  position: absolute;
  top: 100%;
  left: 0;
  width: 220px;
  z-index: 10;
}

.sipFilterColDateRangeContainer>div:nth-child(3) {
  left: 240px;
}

.imgIcon {
  width: 14px;
  height: 14px;
}

.text-blue-italic {
  color: #007ad9 !important;
  font-size: 13px;
  font-style: italic;
}

.text-up {
  color: #007ad9 !important;
  font-size: 13px;
  font-style: italic;
}

.text-uppercase {
  color: var(--brand-color-gray);
  text-transform: uppercase;
  font-weight: bold !important;
}

.text-small {
  font-size: 80%;
  font-weight: 400;
}

.text-font-16 {
  font-size: 14px;
}

.text-muted {
  color: #6c757d !important;
}

.btn.dropdown-toggle {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
  width: 100%;
  background-color: #fff !important;
  border-color: #dee2e6 !important;
}

.bootstrap-select>.dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.bootstrap-select>select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
  z-index: 0 !important;
}

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
}

:not(.input-group)>.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}

.vt-order-footer {
  background-color: #f9f9f9;
  bottom: 10px;
  box-shadow: 0px 5px 20px -5px #ddd;
  position: -webkit-sticky;
  position: sticky;
  border: 1px solid #dfdfdf;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.btn-viettel {
  color: $primary  !important;
  background-color: #fff !important;
  border-color: $primary  !important;
  transition: all 0.2s linear 0s !important;
  outline: none !important;
  font-size: 14px !important;
}

.btn-right {
  float: right;
}

.btn-mg-10 {
  margin: 10px;
}

.btnCus {
  width: 100%;
  height: auto !important;
}

.btnCusSearch {
  width: auto !important;
  height: 38px !important;
}

.form-control-custom {
  width: auto !important;
}


.btn-block {
  display: block;
  width: 100%;
}

.btn-secondary-cus {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

button,
.btn {
  font-size: 14px;
}

html:not([dir=rtl]) .dropdown-toggle::after {
  margin-left: 0.255em;
  float: right;
  color: black;
  margin-top: 14px;
}

#dropdown-item-button-no-margin.dropdown-toggle.btn.btn-primary::after {
  margin-left: 0.255em;
  float: right;
  color: black;
  margin-top: 0px !important;
}

html:not([dir=rtl]) .modal-header .btn-close {
  background-color: #fff;
}

.float-left {
  float: left;
}

.text-align-end {
  text-align: end;
}

.highcharts-credits {
  display: none !important;
}

.font-size-large {
  font-size: 20px;
}

.line {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 1em;
  margin-bottom: 1em;
}

.SipTableFunctionIcon {
  padding: 0;
  background: none !important;
  border: none;
  box-shadow: none;
  margin: 0 5px;
  font-size: 12px;
  height: 28px;
  min-height: 0;
}

.dropdown-menu.show {
  width: 97% !important;
  min-width: 250px !important;
  word-wrap: break-word;
  display: block;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.addPackageItem {
  max-height: 191px;
  overflow-y: auto;
  overflow-x: hidden;
}

.css-jcn4dz-MuiBadge-badge {
  width: 33px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: $primary  !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: $primary  !important;
}

.dropdown-item.active,
.dropdown-item:active {

  background-color: $primary  !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: none !important;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 0px !important;
}

.applyBtn {
  background-color: $primary  !important;
}

.daterangepicker .ranges li.active {
  background-color: $primary  !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: $primary  !important;
}

.btnRangeDate {
  position: relative;
  background-color: #e9ecef !important;
  border-color: #ced4da !important;
  width: 100%;
  color: #000;
  height: 38px !important;

  &>i {
    left: 10px;
    position: absolute;
    top: 8px;
  }

  div {
    margin-left: 24px;
    overflow: hidden;
    text-align: left;
    width: 100%;
  }
}

.textInputSearchQuanLy {
  position: relative;

  .btnCusSearch {
    cursor: pointer;
    right: 0px;
    position: absolute;
    top: 0px;
  }

  input.form-control {
    padding: 0 50px 0 10px;
    width: 100% !important
  }
}

input:focus {
  box-shadow: 0 1px 1px #fff !important;
  border-color: #0d6efd !important;
  border-width: 2px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  margin-top: 5px;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none !important;
}

.css-1vhaqj4-MuiButtonBase-root-MuiButton-root {
  border-radius: 10px !important;
}

.css-ag7rrr-MuiTypography-root {
  font-family: 'FSPFBeauSansPro' !important;
}

.btnInput {
  height: 30px !important;
  width: 30px !important;
  margin-top: 2px !important;
  margin-right: 2px !important;
}
// .react-datepicker__month-container {
//   background-color: #081833;
//   color: #969eac;
//   font-size: 1rem;
//   font-family: 'Mulish';

//   .react-datepicker__month {
//     padding: 1rem 0;
//   }

//   .react-datepicker__month-text {
//     display: inline-block;
//     width: 5rem;
//     margin: 0.5rem;
//     font-size: 1rem;
//     padding: 0.2rem;
//     &:hover {
//       background-color: #534cea;
//     }
//   }

// }